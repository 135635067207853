<template>
  <div class="main-box" style="margin-top: -30px;">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
<!--      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>-->
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        row-key="id"
        style="width: 100%;"
        size="mini">
<!--        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"-->
<!--      <el-table-column-->
<!--          type="selection"-->
<!--          align="center"-->
<!--          width="55">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="title"
          label="名称">
        <template slot-scope="scope">
          <el-input placeholder="请输入名称" v-model="scope.row.title"></el-input>
        </template>
      </el-table-column>
      <el-table-column
          prop="pic"
          label="图标">
        <template slot-scope="scope">
          <div>
            <SelectImgs :selectNum="1" :selectData="[scope.row.pic]" :selectFunc="picChoose" :idx="scope.$index"></SelectImgs>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="ordering"
          label="排序"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-input placeholder="请输入排序" v-model="scope.row.ordering"></el-input>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMenu(scope.$index)">保存</el-button>
          <el-button type="text" size="small" @click="delMenu(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

import SelectImgs from '@/components/SelectImgs'
export default {
  components: {
    SelectImgs
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加分类',
      dialogFormVisible: false,
      pic: [],
      form: {
        id: 0,
        pid: 0,
        title: '',
        pic: '',
        ordering: ''
      },
      formLabelWidth: '120px',
      tableData: [],
      menuType: '1',
    };
  },
  created() {
    this.getMenuAll()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    picChoose(item,index) {
      this.tableData[index].pic = item.pic
    },
    getMenuAll() {
      var that = this
      this.$api.goods.menuAll({}, function (res) {
        if(res.errcode == 0) {
          that.tableData = res.data.filter(item=>{
            return item.pid==0
          })
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addMenu() {
      this.tableData.push({
        id: 0,
        pid: 0,
        title: '',
        pic: '',
        ordering: ''
      })
    },
    editMenu(index) {
      var that = this
      var param = this.tableData[index]
      this.$api.goods.menuEdit(param,function (res) {
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    delMenu(index) {
      var that = this
      this.$confirm('确认要删除这条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.goods.menuDelete({ids: [that.tableData[index].id]},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.tableData.splice(index,1)
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
</style>
