<template>
  <div class="main-box">
    <div class="mg-bt-10">
<!--      <el-button type="primary" size="small" icon="el-icon-edit" @click="showDialog()">编辑大类</el-button>-->
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
<!--      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>-->
    </div>
<!--    <el-tabs type="card" @tab-click="changeType" v-model="menuType" v-if="bigMenuList && bigMenuList.length>0">-->
<!--      <el-tab-pane :label="item.title" :name="item.id.toString()" v-for="(item,index) in bigMenuList" :key="index"></el-tab-pane>-->
<!--&lt;!&ndash;      <el-tab-pane label="膜类" name="2"></el-tab-pane>&ndash;&gt;-->
<!--    </el-tabs>-->
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            row-key="id"
            style="width: 100%;"
            size="mini"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
<!--      <el-table-column-->
<!--              type="selection"-->
<!--              align="center"-->
<!--              width="55">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="title"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
              prop="pic"
              label="图标">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="30" :src="scope.row.pic" v-if="scope.row.pic"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
              prop="ordering"
              label="排序"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editMenu(scope.row)">编辑</el-button>
          <span class="mg-rt-10"></span>
          <el-popconfirm
              title="确定要删除吗？"
              @confirm="delMenu(scope.row)"
          >
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="分类名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="父级分类" :label-width="formLabelWidth" v-if="!form.id || form.pid">
          <el-select v-model="form.pid" placeholder="请选择分类" clearable>
            <el-option :label="item.title" :value="item.id" v-bind:key="index" v-for="(item, index) in treeMenu"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标" :label-width="formLabelWidth">
          <SelectImgs :selectNum="1" :selectData="pic" :selectFunc="picChoose"></SelectImgs>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.ordering" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog @close="getMenuAll"
        title="编辑大类"
        :visible.sync="dialogPayVisible"
        :destroy-on-close="true"
        width="500">
      <big-menu-list></big-menu-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import BigMenuList from "@/components/BigMenuList";
export default {
  components: {
    SelectImgs,
    BigMenuList
  },
  data() {
    return {
      info: false,
      dialogPayVisible: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加分类',
      dialogFormVisible: false,
      pic: [],
      form: {
        id: 0,
        pid: 0,
        title: '',
        pic: '',
        ordering: ''
      },
      formLabelWidth: '120px',
      tableData: [],
      menuType: '1',
      bigMenuList: [],
      treeMenu: []
    };
  },
  created() {
    this.getList();
    this.getMenuAll()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    showDialog() {
      this.dialogPayVisible = true
    },
    changeType(e) {
      this.menuType = e.name
      this.getList()
    },
    picChoose(item) {
      this.pic = [item.pic]
      this.form.pic = item.pic
    },
    getMenuAll() {
      this.$api.goods.menuAll({level:2}, res => {
        if(res.errcode == 0) {
          this.treeMenu = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      var that = this
      that.showLoading()
      this.$api.goods.menuList({}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      var that = this
      this.$api.goods.menuDetail({id: item.id},function (res) {
        if(res.errcode == 0) {
          that.form = res.data
          if(that.form.pid == 0) {
            that.form.pid = ''
          }
          that.pic = [that.form.pic]
          that.dialogFormVisible = true
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addMenu() {
      this.form.id = 0
      this.form.pid = ''
      this.form.title = ''
      this.form.pic = ''
      this.pic = []
      this.form.ordering = ''
      this.dialogFormVisible = true
    },
    saveMenu() {
      var that = this
      var param = {
        id: that.form.id,
        pid: that.form.pid,
        title: that.form.title,
        pic: that.form.pic,
        menuType: that.menuType,
        ordering: that.form.ordering
      }
      if(this.form.id == 0) {
        this.$api.goods.menuAdd(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.getMenuAll()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      } else {
        this.$api.goods.menuEdit(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.getMenuAll()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      }
    },
    delMenu(item) {
      var that = this
      this.$api.goods.menuDelete({ids: [item.id]},function (res) {
        if(res.errcode == 0) {
          that.success('删除成功')
          that.getList()
          that.getMenuAll()
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
